"use client";
import React, { useEffect, useState } from "react";
import MainLayout from "@/components/layout/mainLayout";
import Select from "react-select";
import api from "../../../api";
import BuyNowCart from "@/components/common/BuyNowCart";

import {
  setAddVariantToCart,
  setUpdateQuantityInCart,
  setRemoveLineItemInCart,
  getCheckoutLineItems,
  getProductDetails,
} from "../../util/utils";
import ScoMeta from "@/components/scometa";

const ShopNow = (props: any) => {
  const { data, meta, countryCode } = props;

  const [selectedOption, setSelectedOption]: any = useState(null);
  const [selectedBrandOption, setSelectedBrandOption]: any = useState(null);
  const [selectedDistilleryOption, setSelectedDistilleryOption]: any =
    useState(null);
  const [selectedBrandDistilleryOption, setSelectedBrandDistilleryOption]: any =
    useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [mainData, setMainData] = useState(data?.data || []);
  const [brandData, setBrandData]: any = useState([]);
  const [distilleryData, setDistilleryData]: any = useState([]);

  useEffect(() => {
    if (data && data?.data?.length > 0) {
      for (let index = 0; index < data?.data?.length; index++) {
        const item: any = data?.data[index];
        let shopifyProductID: any = item?.attributes?.shopifyproduct_id;
        let productID: any = `gid://shopify/Product/${shopifyProductID}`;

        getProductDetails(productID, countryCode)?.then((productDetails) => {
          const productPrice: any =
            productDetails?.variants?.[0]?.price?.amount;
          const productStock: any = productDetails?.availableForSale;
          setMainData((prevData: any) => {
            const updatedData: any = [...prevData];
            updatedData[index] = {
              ...updatedData[index],
              attributes: {
                ...updatedData[index].attributes,
                latestPrice: productPrice,
                stock: productStock,
              },
            };
            return updatedData;
          });
        });
      }
    }
    api
      .getBrandList()
      .then((data: any) => {
        setBrandData(data?.data);
      })
      .catch((err: any) => {
        console.log("ERROR: ", err);
      });
    api
      .getDitilleryList()
      .then((data: any) => {
        setDistilleryData(data?.data);
      })
      .catch((err: any) => {
        console.log("ERROR: ", err);
      });
  }, []);

  const productCountToShow: any = showAllProducts ? data?.data?.length : 6;

  const toggleProductVisibility = () => {
    setShowAllProducts(!showAllProducts);
  };

  const options: any = [
    { value: "price_low_to_high", label: "Price low to high" },
    { value: "price_high_to_low", label: "Price high to low" },
    { value: "name_a_to_z", label: "Sorting by A-Z" },
    { value: "name_z_to_a", label: "Sorting by Z-A" },
  ];

  const customStyles: any = {
    control: (provided: any) => ({
      ...provided,
      border: "none",
      borderBottom: "1px solid #181818",
      borderRadius: 0,
      boxShadow: "none",
      width: "100%",
      maxWidth: "500px",
      fontFamily: "Raleway",
      fontWeight: 600,
      fontSize: 16,
      color: "#181818",
      "&:hover": {
        borderBottom: "1px solid #181818",
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      paddingLeft: 0,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontFamily: "Raleway",
      fontWeight: 600,
      fontSize: 16,
      cursor: "pointer",
      color: state.isSelected ? "#FFFFFF" : "#181818",
      backgroundColor: state.isSelected ? "#B3936D" : "#FFFFFF",
      "&:hover": {
        backgroundColor: "#B3936D",
        color: "#FFFFFF",
      },
    }),

    placeholder: (provided: any, state: any) => ({
      ...provided,
      fontFamily: "Raleway",
      fontWeight: 600,
      fontSize: 16,
      color: "#181818",
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: "#181818",
      padding: 0,
      "&:hover": {
        color: "#181818",
      },
    }),
    indicatorSeparator: () => ({ display: "none" }),
  };
  const formattedBrandData = brandData.map((item: any) => {
    return {
      value: item.attributes.name,
      label: item.attributes.name,
    };
  });

  useEffect(() => {
    if (selectedBrandOption) {
      setSelectedDistilleryOption(null);

      const selectedBrandDistilliesOptions = brandData.filter((item: any) => {
        return item?.attributes?.name === selectedBrandOption.value;
      });

      if (
        selectedBrandDistilliesOptions.length > 0 &&
        selectedBrandDistilliesOptions[0]?.attributes?.distilleries?.data
          ?.length > 0
      ) {
        setSelectedBrandDistilleryOption(
          selectedBrandDistilliesOptions[0]?.attributes?.distilleries?.data
        );
      } else {
        setSelectedBrandDistilleryOption(null); // Clear if no distilleries found
      }
    }
  }, [selectedBrandOption, brandData]);

  const formattedDistilleryData = selectedBrandDistilleryOption?.map(
    (item: any) => {
      return {
        value: item.attributes.name,
        label: item.attributes.name,
      };
    }
  );

  // const formattedDistilleryData = distilleryData.map((item: any) => {
  //   return {
  //     value: item.attributes.name,
  //     label: item.attributes.name,
  //   };
  // });

  const sortProducts = (products: any) => {
    if (!selectedOption) return products;

    const getPriceAsNumber = (priceString: any) => {
      // Remove currency symbol and any spaces, then convert to a number
      const numericString = priceString?.replace(/[^0-9.-]+/g, "");
      return parseFloat(numericString);
    };

    if (selectedOption?.value === "price_low_to_high") {
      return products?.sort((a: any, b: any) => {
        const priceA = getPriceAsNumber(a?.attributes?.latestPrice || "0");
        const priceB = getPriceAsNumber(b?.attributes?.latestPrice || "0");

        return priceA - priceB;
      });
    } else if (selectedOption?.value === "price_high_to_low") {
      return products?.sort((a: any, b: any) => {
        const priceA = getPriceAsNumber(a?.attributes?.latestPrice || "0");
        const priceB = getPriceAsNumber(b?.attributes?.latestPrice || "0");

        return priceB - priceA;
      });
    } else if (selectedOption?.value === "name_a_to_z") {
      return products.sort((a: any, b: any) =>
        a?.attributes?.name?.localeCompare(b?.attributes?.name)
      );
    } else if (selectedOption.value === "name_z_to_a") {
      return products?.sort((a: any, b: any) =>
        b?.attributes?.name?.localeCompare(a?.attributes?.name)
      );
    }

    return products;
  };

  const filterProductsByBrandAndDistillery = (products: any) => {
    let filteredProducts = products;
    if (selectedBrandOption) {
      filteredProducts = filteredProducts.filter(
        (product: any) =>
          product?.attributes?.brand?.data?.attributes?.name ===
          selectedBrandOption?.value
      );
    }

    if (selectedDistilleryOption) {
      filteredProducts = filteredProducts.filter(
        (product: any) =>
          product?.attributes?.distillery?.data?.attributes?.name ===
          selectedDistilleryOption?.value
      );
    }

    return filteredProducts;
  };

  const sortedProducts: any = sortProducts(mainData);
  const filteredProducts = filterProductsByBrandAndDistillery(sortedProducts);

  const addToCart = (data: any) => {
    const productId: any = `gid://shopify/ProductVariant/${data.attributes.shopify_id}`;
    const lineItemsToAdd = [
      {
        variantId: productId,
        quantity: 1,
      },
    ];
    if (countryCode) {
      setAddVariantToCart(lineItemsToAdd, countryCode).then((res) => {
        setCheckout({
          ...res,
        });
        toggleCartSidebar();
      });
    }
  };

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const toggleCartSidebar = () => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });

    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  }, []);

  const handleClearAll = () => {
    setSelectedOption(null);
    setSelectedBrandOption(null);
    setSelectedDistilleryOption(null);
    setSelectedBrandDistilleryOption(null);
  };

  const ProductCard = React.memo((props: any) => {
    const [showAddToCart, setShowAddToCart] = useState(false);

    const stock = props?.item?.attributes?.stock;
    const price = props?.item?.attributes?.latestPrice;

    useEffect(() => {
      if (countryCode) {
        if (countryCode === "en-GB" || countryCode === "en-US") {
          setShowAddToCart(true);
        }
      }
    }, []);

    return (
      <>
        <div className="product_card" key={props?.index}>
          <div className="product_upper_section">
            <div className="product_img_section">
              <img
                src={
                  props?.item?.attributes?.feature_image?.data?.attributes?.url
                }
                alt={`Duncan Taylor Whisky`}
                className="bottle_img"
              />
            </div>
            <div className="product_contains">
              <h2>{props?.item?.attributes?.name}</h2>
              {price && (
                <h6>{`EX VAT Price: ${
                  countryCode === "en-US" ? "$ " : "£ "
                }${price}`}</h6>
              )}
            </div>
          </div>
          <div className="product_cart_section">
            {stock &&
            showAddToCart &&
            props?.item?.attributes?.shopifyproduct_id &&
            props?.item?.attributes?.shopify_id ? (
              <button
                className="btn_buy_noe"
                onClick={() => addToCart(props?.item)}
              >
                BUY NOW
              </button>
            ) : !stock ? (
              <button className="btn_buy_noe btn_disabled">
                NOT AVAILABLE
              </button>
            ) : null}
          </div>
        </div>
      </>
    );
  });

  return (
    <>
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      <head>
        <ScoMeta meta={meta ? meta : {}} />
      </head>
      <MainLayout checkout={checkout} toggleCartSidebar={toggleCartSidebar}>
        <div className="shop-now-wrapper">
          <div className="shop_now_main_container">
            <div className="shop_now_filters">
              <div className="shop_now_options">
                <Select
                  value={selectedBrandOption}
                  onChange={setSelectedBrandOption}
                  options={formattedBrandData}
                  placeholder="Filter by brand"
                  styles={customStyles}
                  isSearchable={false}
                />
              </div>
              {selectedBrandDistilleryOption && (
                <div className="shop_now_options">
                  <Select
                    value={selectedDistilleryOption}
                    onChange={setSelectedDistilleryOption}
                    options={formattedDistilleryData}
                    placeholder="Filter by distillery"
                    styles={customStyles}
                    isSearchable={false}
                  />
                </div>
              )}

              <div className="shop_now_options">
                <Select
                  value={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  placeholder="Sort by"
                  styles={customStyles}
                  isSearchable={false}
                />
              </div>

              <span
                onClick={handleClearAll}
                style={{
                  cursor: "pointer",
                  color: "#181818;",
                  fontSize: "14px",
                  textTransform: "uppercase",
                  textDecoration: "underline",
                  fontWeight: "800",
                }}
              >
                Clear All
              </span>
            </div>

            <div className="shop_now_product_cards">
              <div className="shop_now_title">
                {filteredProducts?.length > 0 ? "Shop Now" : "No Result Found"}
              </div>

              <div className="product_card_wrapper">
                {filteredProducts?.map((item: any, index: number) => {
                  return <ProductCard item={item} index={index} />;
                })}
              </div>
              {/* <div className="product_card_wrapper">
                {filteredProducts
                  ?.slice(0, productCountToShow)
                  ?.map((item: any, index: number) => {
                    return <ProductCard item={item} index={index} />;
                  })}
              </div> */}
              {/* {showAllProducts ? (
                <button
                  className="btn_view_all"
                  onClick={toggleProductVisibility}
                >
                  VIEW LESS
                </button>
              ) : (
                <button
                  className="btn_view_all"
                  onClick={toggleProductVisibility}
                >
                  VIEW MORE
                </button>
              )} */}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default ShopNow;
